<template>
  <div class="wameed-dashboard-page-content">

    <div>
      <filter-header
          :content="filterContent"
          @applyFilter="applyFilter"
          @resetFilter="resetFilter"
          @orderApplicants="orderData"

          :searchPlaceholder="$t('common.search') + ' ...'"
          :btnTitle="$t('common.filter_data')"
          :menuTitle="$t('common.order_by') + ' : '"
          :subTitle="'asdf'"
          :orderByItems=" [
        {
          name: this.$i18n.t('common.newest'),
          id: 'desc',
        },
        {
          name: this.$i18n.t('common.older'),
          id: 'asc',
        },
        {
          name: this.$i18n.t('common.alphabetical'),
          id: 'name',
        },
      ]"
      />
    </div>
    <section
        class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
        overflow-hidden
      ">
      <div class="d-flex flex-wrap flex-1 ">
        <b-col lg="12" md="12" class="px-0">

          <div class="w-table">
            <w-tables
                :noDataTitle="$t('table.no_data_title')"
                :noDataSubtitle="$t('table.no_data_subtitle')"
                v-if="getData"
                :per-page="filterData.per_page"
                :page-number="filterData.page"
                :items="getData"
                :fields="tableFields"

                :custimized-items="[

                { name: 'used_at' },
                { name: 'email' },
                { name: 'order_no' },
                { name: 'gender' },
                { name: 'action' },
              ]"
            >


              <template slot="used_at" slot-scope="{ data }">
                <div class="d-flex">
                  <span class="text-font-main mx-3">
                    {{ formatDate(data.item.used_at, 'YYYY/MM/DD') }}
                  </span>
                </div>
              </template>


              <template slot="gender" slot-scope="{ data }">
                {{ getGender(data.item.gender) }}
              </template>


              <template slot="order_no" slot-scope="{ data }">
                #{{ data.item.order_no }}
              </template>

              <template slot="email" slot-scope="{ data }">
                <check-value :value="data.item.email"/>
              </template>


              <template slot="action" slot-scope="{ data }">
                <b-dropdown
                    variant="background"
                    class="table-option"
                    toggle-class="text-decoration-none rounded-10 px-2 py-2 "
                    menu-class="rounded-8"
                    no-caret
                    dropleft
                >
                  <template v-slot:button-content>
                    <vertical-dots-icon/>
                  </template>


                  <b-dropdown-item
                      :to="goToDetail(data.item.id)"
                  >
                    <span class="text-regular-14 text-font-secondary permission-show-detail">
                      <eyeon-icon class="mx-2"/>
                      {{ $t('common.show_details') }}
                    </span>
                  </b-dropdown-item>


                </b-dropdown>
              </template>
            </w-tables>
          </div>

        </b-col>

        <b-col lg="12" md="12" class="px-0">
          <wameed-pagination
              v-if="getTotal"
              v-model="filterData.page"
              :page="filterData.page"
              :total-items="getTotal.totalItems"
              :per_page="filterData.per_page"
              :menu-title="$t('common.show_results')"
              @changePage="changePage"
              @changeCurrentPage="changeCurrentPage"
          />
        </b-col>

      </div>

    </section>

  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import FilterHeader from '@/components/wameed/WameedFilterHeader.vue';
import {WameedPagination, WameedSwitch, WameedTables as WTables} from 'wameed-ui/dist/wameed-ui.esm'
import CheckValue from "@/components/checkValue.vue";
import CheckPrice from "@/components/checkPrice.vue";
import NameTile from "@/components/nameTile.table.vue";
import DiscountStatusBadge from "@/views/pages/discounts/components/discountStatusBadge.vue";
import DiscountCategory from "@/views/pages/discounts/components/discountCategory.vue";
import DiscountOn from "@/views/pages/discounts/components/discountOn.vue";

export default {
  components: {
    DiscountOn,
    DiscountCategory,
    DiscountStatusBadge,
    NameTile,
    CheckPrice,
    CheckValue,
    PageHeader,
    FilterHeader,
    WameedPagination,
    WameedSwitch,
    WTables,
  },
  data() {
    return {
      currentItemData: null,
      filterData: {
        status: '',
        page: 1,
        per_page: 20,
        order_by: 'desc',
        search: '',
      },
      filterContent: [
        {
          title: this.$i18n.t('filters.date'),
          key: "Date",
          id: "id",
          name: "title",
          selectedData: [],
          translate: true,
          type: "date",
          startDate: null,
          endDate: null,
        },
        {
          title: this.$i18n.t('filters.gender'),
          key: 'gender',
          id: 'id',
          type: 'text',
          name: 'title',
          selectedData: [],
          translate: true,
          data: [
            {
              id: 'male',
              title: this.$i18n.t('common.male'),
            },
            {
              id: 'female',
              title: this.$i18n.t('common.female'),
            },
          ],
        }
      ],
      tableFields: [
        {
          key: "index",
          label: "#",
          sortable: true,
        },

        {
          key: 'name',
          label: this.$i18n.t('discounts.table.name'),
          sortable: false,
          tdClass: 'w-2/8'
        },
        {
          key: 'gender',
          label: this.$i18n.t('users.table.type'),
          sortable: false,
          tdClass: 'w-1/8'
        },

        {
          key: 'phone',
          label: this.$i18n.t('users.table.phone'),
          sortable: false,
          tdClass: 'w-1/8'
        },
        {
          key: 'email',
          label: this.$i18n.t('users.table.email'),
          sortable: false,
          tdClass: 'w-1/8'
        },
        {
          key: 'used_at',
          label: this.$i18n.t('discounts.table.used_at'),
          sortable: false,
          tdClass: 'w-1/8'
        },

        {
          key: 'order_no',
          label: this.$i18n.t('orders.table.no'),
          sortable: false,
          tdClass: 'w-1/8'
        },
        {
          key: 'action',
          label: this.$i18n.t('table.options'),
          tdClass: 'w-1/8'
        },
      ],
    };
  },
  props: {
    discountId: [String, Number]
  },
  computed: {
    ...mapGetters({
      getData: 'admin/discounts/getUsageData',
      getTotal: 'admin/discounts/getUsageTotal',
    }),
  },


  methods: {

    getGender(gender) {
      if (gender === 2)
        return this.$i18n.t('common.unregister');
      if (gender === 1)
        return this.$i18n.t('common.male')

      return this.$i18n.t('common.female')
    },
    ...mapActions({
      _getData: 'admin/discounts/discountUsage',
    }),


    goToDetail(id) {
      return {
        name: 'order-detail',
        params: {lang: this.$i18n.locale, id},
      };
    },


    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
        page:1,
      };
      this.loadData();
    },

    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },

    loadData() {
      this._getData({id: this.discountId, ...this.filterData});
    },


    applyFilter() {
      this.filterContent.forEach(item => {
        if (item.key === "Date") {
          if (item.startDate)
            this.filterData['startDate'] = item.startDate.toString();
          if (item.endDate)
            this.filterData['endDate'] = item.endDate.toString();
        } else {
          this.filterData[item.key] = item.selectedData.toString();
        }
      });
      this.loadData();
    },


    resetFilter() {
      this.filterContent.forEach((item, i) => {
        this.filterContent[i].selectedData = [];
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },


    orderData(item) {
      this.filterData = {
        ...this.filterData,
        ...item,
      };
      this.loadData();
    },
  },
};
</script>

import i18n from "@/libs/i18n";


const userTargets = [
    {
        id: 0,
        name: i18n.t('common.all'),
    },
    {
        id: 2,
        name: i18n.t('users.client.title'),
    },
    {
        id: 3,
        name: i18n.t('users.vendor.title'),
    },
    {
        id: 4,
        name: i18n.t('users.driver.title'),
    },

    {
        id: 5,
        name: i18n.t('common.custom'),
    },


];

function filterUserTarget(id) {
    return userTargets.find((item) => item.id === id);
}

export {userTargets, filterUserTarget};

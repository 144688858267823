const notificationTarget = {

  /** there is no condition*/
  none: '0',

  /** users ids */
  users: 'urs',

  /** users types (drivers, customers, admins, ...etc) */
  usersType: 'urs_tp',

  /** users ages*/
  age: 'age',

  /** device platform*/
  platform: 'plt',

  /** users genders */
  gender: 'gnd'


};


export { notificationTarget };

<template>

  <b-row class="package-details-table__body">
    <b-col sm="4" md="4" lg="3" class="package-details-name">
      <p class="mb-0">
        {{ title }}
      </p>
    </b-col>
    <b-col class="package-details-value">
      <p class="mb-0">
        {{ value }}
        <slot v-if="!value" name="value"/>
        <slot name="detail"/>
        <div v-if="detail">
          <div class="d-flex align-items-center" style="gap: 16px">
            <div class="d-inline-block">
              {{ $t("discounts.talqah_discount") }} :
            </div>
            <div class="d-inline-block">
              {{ detail.talqah_discount }}
            </div>
          </div>

          <div class="d-flex align-items-center" style="gap: 16px">
            <div class="d-inline-block">
              {{ $t("discounts." + translate) }} :
            </div>
            <div class="d-inline-block">
              {{ detail.provider_discount }}
            </div>
          </div>
        </div>
      </p>
    </b-col>
  </b-row>

</template>

<script>


export default {
  props: {
    title: String,
    translate: String,
    value: [String, Number],
    detail: Object
  }
};
</script>
